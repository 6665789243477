<template>
 <div class="cont border-btm">

  <div class="footer">
    <div class="bt-flex"> <h4>Connect with us: </h4>
    <h4>connect.kdplabs@gmail.com</h4></div>
   
  </div>
  
</div>

    
 

</template>

<script>
export default {
  name: 'FooterSection',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {

  display: flex;
  gap: 30px; 
  color:#a7a7a7; 
  margin-top: 60vh; 
  justify-content: center;
  padding:50px; 
}
</style>
