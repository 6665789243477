<template>
 <NavBar/>
 <HeroSection /> 
 <div class="cardHolder">
   <div class="card-group">
    <HeroCard image="local_library" delay="100"  title="Learn" text="Learn to code and grow your knowledge. Hands on practical experience with various stages."/> 
    <HeroCard image="tips_and_updates" delay="300" title="Innovate" text="Use your skills to innovate and work on several experiments" /> 
    <HeroCard image="check" delay="500" title="Grow" text="Take your career to another level." /> 
 </div>
 </div>


<KodableSection /> 
<RootRobot />
<ScratchSection />
<FooterSection />

</template>

<script>
import NavBar from './components/NavBar.vue'
import HeroSection from './components/HeroSection.vue'
import HeroCard from './components/HeroCard.vue'
import KodableSection from './components/KodableSection.vue'
import RootRobot from './components/RootRobot.vue'
import ScratchSection from './components/ScratchSection.vue'
import FooterSection from './components/FooterSection.vue'

export default {
  name: 'App',
  components: {
    NavBar, 
    HeroSection, 
    HeroCard, 
    KodableSection, 
    RootRobot, 
    ScratchSection, 
    FooterSection
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin:0; 
  padding:0; 
}

body{
    margin:0; 
  padding:0; 
  background: black;
}
.card-group{
  padding:30px; 
  box-shadow: 1px 1px 5px #ff9800;
  margin-bottom: 30px;
  gap:10px;
}

.mg {
    margin-bottom: 40px;
}

.border-btm {
    border-bottom: 1px solid #ff9800;
}

.cardHolder{
  display:flex; 
  justify-content: center;
}

@media (max-width: 1250px)
{
  .card-group {
    flex-wrap: wrap;
    max-width: 90%;
    box-shadow:none; 
}

.heroText h1{
  font-size: 30px;

}
.heroText h4{
  font-size: 16px;

}

}

</style>
