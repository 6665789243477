<template>
 <div>
  
  <div class="header">
      <div class="navbar">
      <div class="logo">
             <img src="/logo-white.png" alt="">
      </div>

     <h2>KDP Labs</h2>
   
   </div>
   
  </div>
  
 </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header{
    box-shadow: 3px 3px 3px #aaaaaa;
    background:black; 
}


.navbar{
  display:flex; 
  justify-content:center;
  gap:20px

}

.navbar img{
  height:75px; 
  width:75px; 
  
}



.navbar h2{
 
  color:#ffba00; 
  font-size: 2rem;
}

.block{
  flex-grow: 1;
}
</style>
