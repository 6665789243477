<template>
 
      <div class="card" data-aos="zoom-out-up" data-aos-duration="1000" :data-aos-delay="delay" data-aos-easing="ease-in">
      <div class="circle">
           <span class="material-symbols-outlined">
          {{image}}
        </span>
      </div>
   
       <div class="card-body">
          <h2 class="card-title">{{title}}</h2>
          <p class="card-text">{{text}}</p>
       </div>
      </div>


    
 

</template>

<script>
export default {
  name: 'HeroCard',
  props: {
    image: String, 
    title: String, 
    text: String, 
    delay: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.material-symbols-outlined{
  font-size: 8rem;
color:#fcf1bf;
}

.card{
  background:black; 
  margin: 11px;
   flex: 1 1 0;
       box-shadow: 1px 1px 5px #ff9800;
}

.circle{
  border-radius: 10px;
  
    padding: 10px;
}

.card-body{
  color:white; 
}

@media (max-width: 1250px) {
 .card{
  flex:auto; 
}
}

</style>
