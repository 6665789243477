<template>
 <div class="cont border-btm">

      <img class="brand" src="https://cdn-images-1.medium.com/max/1196/1*wQxi-xi_tKFZN2pRDNoL4w@2x.png" alt="">
  <h2>Creative coding for everyone.</h2>
  <h3>Let your child express their creative iedas through code</h3> 
  <p>This free resorce helps your child bring their creativity and coding skills together </p>  
  <p>Get strated with coding cards !!!</p>
  <a href="https://scratch-foundation.myshopify.com/collections/all-products/products/scratch-coding-cards-creative-coding-activities-for-kids" target="_blank" class="btn btn-danger mg">Get Scratch cards!!</a>



 <div   class="cartoon"> </div>
  
</div>

    
 

</template>

<script>
export default {
  name: 'ScratchSection',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cartoon{
  width:100%; 
  height: 70vh;
  background-image: url('../assets/scratch-mov.gif');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

    position: relative;
    top: -60vh;
    margin-bottom: -60vh;
    z-index: -1;
    opacity: 0.55;

}

h2, h3,  p, a{
  color: white;
}

.mg{
   margin-bottom: 40px;
}

.brand{
  width:300px; 
  
}


.container{
  text-align: center;
}

.cont{
  margin-bottom: -400px;

}
</style>
