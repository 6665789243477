<template>
 <div class="mg border-btm">
  <img class="mg" src="/kodable-logo.png" alt="">
  <h2>Foster creativity and critical thinking.</h2>
  <img  data-aos="zoom-out-up" data-aos-duration="1000" data-aos-easing="ease-in" class="cartoon mg" src="https://global-uploads.webflow.com/62f10984b7ece0cd68b339a1/62f3b06c87c554dc1b7886ba_characters.svg" alt=""/>
  <h3>Build a foundation for your child's future.</h3> 
  <p>Kids learn core programming concepts through engaging games developed with real teachers and kids.</p>  
  <p>Kids love Kodable! So do teachers and parents!</p>
  <a href="https://www.kodable.com/parent-plans" target="_blank" class="btn btn-danger mg">Get Kodable</a>
</div>

    
 

</template>

<script>
export default {
  name: 'KodableSection',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cartoon{
  width:100%; 
}

h2, h3,  p, a{
  color: white;
}

.mg{
   margin-bottom: 40px;
}
</style>
