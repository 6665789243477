<template>
 <div>
      <div class="hero">
        <div class="heroText">
          <h1>
            Invest in your Kid's future. 
          </h1>
          <h4>
            Help them learn coding while they play!!!
          </h4>

      </div>
</div>

    
 
 </div>
</template>

<script>
export default {
  name: 'HeroSection',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.hero{
  background-image: url('https://images.unsplash.com/photo-1668525543127-1e3820020361?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80');
  height:80vh; 
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroText{

color: #ff9800;
    position: relative;
    top: 20vh;
    
    align-items: center;
    background: #000000a6;
    padding: 0 30px;

}

.heroText h1{

  font-size: 60px;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
}
</style>
